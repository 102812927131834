header {
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 60px 40px;
}

.logo-roducoins{
  max-width: 280px;
}

.app-logo {
  font-family: "Asap", sans-serif;
  color: white;
  font-size: 64px;
  font-weight: 900;
  position: relative;
}

.app-logo::after {
  content: '';
  display: block;
  position: absolute;
  width: 40px;
  height: 40px;
  background-color: #FFC300;
  border-radius: 50%;
  top: 23px;
  left: 177px;
}

.login-section {
  font-family: "Asap", sans-serif;
}

.login-section span {
  color: #ddd;
  font-weight: 400;
}

.login-section span:hover{
  color: white;
}

.register-button {
  display: inline-block;
  font-family: "Asap", sans-serif;
  font-size: 16px;
  background-color: #FB0D50;
  color: white;
  padding: 20px 50px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: 600;
  margin-left: 40px;
  transition: all .2s;
  border: none;
  cursor: pointer;
}

.register-button:hover {
  background-color: #e90b4a;
}

.main {
  max-width: 1200px;
  margin: 0 auto;
}

.main h2 {
  font-family: "Asap", sans-serif;
  font-weight: 900;
  color: white;
  font-size: 96px;
  max-width: 770px;
  position: relative;
  z-index: 1;
  padding: 0;
}

.main-effect {
  height: 550px;
  width: 450px;
  border-radius: 50px 0 0 50px;
  background-color: #313131;
  position: absolute;
  right: 0;
  top: 20%;
}

.iniciar-button {
  background-color: #FB0D50;
  color: white;
  padding: 20px 50px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: 800;
  font-size: 16px;
  font-family: "Asap", sans-serif;
  margin-bottom: 100px;
  display: inline-block;
  transition: all .2s;
  border: 0;
  cursor: pointer;
}

.iniciar-button:hover {
  background-color: #e90b4a;
}

.iniciar-button:disabled{
  cursor: not-allowed;
}

.footer {
  width: 100%;
  height: 160px;
  background-color: #555555;
}

.footer section {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.important-links {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 40px;
  gap: 5px;
  font-family: "Asap", sans-serif;
  font-size: 14px;
  font-weight: 600;
  color: white;
}

.social h3 {
  font-family: "Asap", sans-serif;
  font-size: 20px;
  font-weight: 900;
  color: white;
}

@media (max-width: 760px) {

  header {
    position: fixed;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    padding: 20px 20px;
    z-index: 2;
    background-color: #555;
  }

  .logo-roducoins{
    max-width: 30%;
  }

  .register-button {
    padding: 10px 20px;
    margin-left: 20px;
}

  .main{
    margin-top: 120px;
    padding: 0 40px;
  }

  .main h2{
    font-size: 56px;
    padding: 0;
  }

  .main-effect {
    opacity: 50%;
  }

  .iniciar-button{
    position: relative;
    z-index: 1;
    margin-bottom: 60px;
  }

  .footer{
    position: absolute;
    height: 140px;
    bottom: 0;
  }

  .footer section{
    padding: 0 40px;
  }
}
