/* src/components/Modal/Modal.css */

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 999;
  }
  
  .modal-wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
  }
  
  .modal {
    background: #282c34;
    padding: 40px;
    max-width: 650px;
    min-width: 500px;
    width: 90%;
    position: relative;
    border-radius: 8px;
    box-sizing: border-box;
  }
  
  .modal-close-button {
    position: absolute;
    top: 10px;
    right: 15px;
    background: transparent;
    border: none;
    font-size: 30px;
    cursor: pointer;
    color: white;
  }
  
  .modal-close-button:focus {
    outline: none;
  }
  
  .modal-content {
    /* Estilos para o conteúdo do modal */
  }
  
  @media (max-width: 760px) {
    .modal{
      min-width: 350px;
      width: 100%;
    }
  }